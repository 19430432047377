import React, { memo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import IconAngle from 'components/Icons/Angle'

import { editAttendee, editAttendeeCoupon, removeAttendee } from 'store/Event/actions'

import { CloseIcon, CompleteIcon, WarningIcon } from '../../Icons/Icons'

import QuestionField from './QuestionField'

import s from '../../Event.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const AttendeeForm = ({ attendee, attendeeIndex, edit, lng, ...props }) => {
	const [t] = useTranslation()
	const [show, setShow] = useState(true)
	const keysAttendee = Object.keys(attendee?.questionsOrder)
	const firstName = attendee?.questionsOrder[keysAttendee[0]]?.value
		? attendee?.questionsOrder[keysAttendee[0]]?.value
		: ''
	const lastName = attendee?.questionsOrder[keysAttendee[1]]?.value
		? attendee?.questionsOrder[keysAttendee[1]]?.value
		: ''
	const fieldsRequired = []
	let questionsOrderKeys = Object.keys(attendee?.questionsOrder)
	questionsOrderKeys.forEach((key) => {
		if (attendee?.questionsOrder[key]?.isRequired) {
			fieldsRequired.push(key)
		}
	})
	const checkFieldsOnRequired = () => {
		let fieldsWithValue = []
		for (let i = 0; i < keysAttendee.length; i++) {
			if (attendee?.questionsOrder[keysAttendee[i]]?.value) {
				fieldsWithValue.push(attendee?.questionsOrder[keysAttendee[i]]?.id)
			}
		}
		return fieldsRequired.length === fieldsWithValue.length
	}
	return (
		<div className={cx('item')}>
			<span className={cx('item-number', { active: show })}>{attendeeIndex + 1}</span>
			<div className={cx('item-wrap')}>
				<div
					className={cx('item-delete')}
					onClick={(e) => {
						setTimeout(() => props.getDiscountSum(props.id), 0)

						setTimeout(props.getTotalSum, 0)

						props.removeAttendee(e)

						if (props.chart && props.form.seatId) {
							props.chart.deselectObjects([props.form.seatId])
						}
					}}>
					<CloseIcon fill={show ? 'var(--accent-color)' : '#8C8C8C'} />
				</div>
				<div className={cx('item-header')} onClick={() => setShow(!show)}>
					<h5 className={cx('fz1628', 'fw600')}>
						{checkFieldsOnRequired() ? <CompleteIcon /> : <WarningIcon />}
						<div className={cx('saved-ticket')}>
							<span>
								{t('event.ticket.ticket')} №{attendeeIndex + 1}{' '}
							</span>
							{firstName || lastName ? (
								<p>{`(${firstName}  ${lastName})`}</p>
							) : (
								t('event.ticket.inputInfo')
							)}
						</div>
					</h5>
					<div className={cx('header-arrow')}>
						<IconAngle
							width={6}
							rotate={show ? '-90' : '90'}
							height={12}
							color={show ? 'var(--accent-color)' : '#8C8C8C'}
						/>
					</div>
				</div>
				{show && (
					<div className={cx('item-content', { showItem: show })}>
						<div className={cx('item-fields')}>
							{Object.keys(attendee.questionsOrder)
								?.sort(
									(a, b) => attendee.questionsOrder[a]?.priority - attendee.questionsOrder[b]?.priority
								)
								?.map((questionId) => {
									const question = attendee?.questionsOrder[questionId]
									return (
										<QuestionField
											{...question}
											lng={lng}
											isMultilanguage={props.isMultilanguage}
											defaultLanguage={props.defaultLanguage}
											edit={(v) => {
												edit && edit(questionId, v)
											}}
											labelCustomName={'labelInput'}
											inputWrapCustomClass={'defaultInput'}
											key={questionId}
										/>
									)
								})}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

AttendeeForm.propTypes = {
	ticketTypeId: PropTypes.string.isRequired,
	attendeeIndex: PropTypes.number.isRequired,
	attendee: PropTypes.object
}

const mapDispatch = (dispatch, props) => ({
	edit(questionId, value) {
		const { attendeeIndex, ticketTypeId } = props
		dispatch(
			editAttendee({
				attendeeIndex,
				ticketTypeId,
				questionId,
				value
			})
		)
	},
	removeAttendee(e) {
		e.preventDefault()
		const { attendeeIndex, ticketTypeId } = props
		dispatch(removeAttendee({ attendeeIndex, ticketTypeId }))
	},
	editCoupon(value) {
		const { attendeeIndex, ticketTypeId } = props

		dispatch(editAttendeeCoupon({ attendeeIndex, ticketTypeId, value }))
	}
})
export default compose(connect(null, mapDispatch))(memo(AttendeeForm))
